import React from 'react';
import { Link } from 'gatsby';
import * as styles from './header.module.scss';
import CartIndicator from '../cart/cartIndicator';
import Icon from '../icon/icon';

interface Props {
  siteTitle: string;
}

const Header = ({ siteTitle }: Props) => {
  return (
    <header className={`${styles.header} container-fluid`}>
      <div className="centered">
        <nav className={styles.nav}>
          <div className={styles.navLeft}>
            <Link to="/" className={styles.logo}>
              <img src="/images/logo.svg" width="34" height="32" alt="Logo" />
            </Link>
            <ul className={styles.menu}>
              <li>
                <Link to="/karkotok" className={styles.link} activeClassName="active" partiallyActive={true}>Karkötők</Link>
              </li>
            </ul>
          </div>
          <div className={styles.navRight}>
            <ul className={styles.menu}>
              <li>
                <Link to="/favourites" className={styles.link} activeClassName="active" partiallyActive={true} aria-label="Kedvencek">
                  <Icon className={styles.heartIcon} name="heart" size={24} /><span className={styles.linkText}>Kedvencek</span>
                </Link>
              </li>
              <li>
                <CartIndicator />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
};

export default Header;