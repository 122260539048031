/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { isPlatformBrowser } from '../utils/platform';
import Footer from './footer/footer';
import Header from './header/header';
import NewsBanner from './newsBanner/newsBanner';
import UnsupportedBrowser from './unsupportedBrowser/unsupportedBrowser';

interface Props {
  location: { pathname: string };
  children: any;
}

const Layout = ({ children, location }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark {
        html
        frontmatter {
          id
          expiry
        }
      }
    }
  `);

  const page = location.pathname.split('/')[1];
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    setShowCookieConsent(isPlatformBrowser && !Cookies.get('cookie-consent'));
  }, []);

  return (
    <>
      <div className={`page ${page && page + '-page'} ${showCookieConsent ? 'cookie-consent-visible' : ''}`}>
        <UnsupportedBrowser />

        <div className="top">
          <NewsBanner
            content={data.markdownRemark.html}
            id={data.markdownRemark.frontmatter.id}
            expiry={data.markdownRemark.frontmatter.expiry}
          />
          <Header siteTitle={data.site.siteMetadata.title} />
        </div>
        <main>{children}</main>
        <Footer siteTitle={data.site.siteMetadata.title} />
        <CookieConsent
          disableStyles={true}
          cookieName="cookie-consent"
          containerClasses="cookie-consent"
          contentClasses="cookie-consent-content"
          buttonText="OK 👍"
          buttonClasses="accept-button button button-primary"
          enableDeclineButton
          declineButtonText="Nem, köszi"
          declineButtonClasses="decline-button button button-secondary"
          sameSite="lax"
          onDecline={() => {
            setShowCookieConsent(false);
          }}
          onAccept={() => {
            // re-enable Google Analytics if it was disabled previously
            window[`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`] = false;
            Cookies.remove(`ga-disable-${process.env.GATSBY_GA_TRACKING_ID}`);

            (window as any).trackGoogleAnalytics();
            (window as any).trackHotjar();
            setShowCookieConsent(false);
          }}
        >
          A felhasználói élmény fokozása érdekében <Link to="/legal/cookie-policy">sütiket</Link> használunk.
        </CookieConsent>
      </div>
    </>
  );
};

export default Layout;
