import React, { useState, useEffect } from 'react';
import localStorageService from '../../utils/localStorageService';
import { isPlatformBrowser } from '../../utils/platform';
import Icon from '../icon/icon';
import * as styles from './newsBanner.module.scss';

interface Props {
  id: string;
  content: string;
  expiry?: Date;
  onVisibilityChange?: (visible: boolean) => void;
}

const NewsBanner = ({ id, content, expiry, onVisibilityChange }: Props) => {
  const todayMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  const expiryMidnight = new Date(new Date(expiry).setHours(0, 0, 0, 0));
  const [visible, setVisibility] = useState(false);

  const dismiss = () => {
    setVisibility(false);
    onVisibilityChange && onVisibilityChange(false);
    localStorageService.set(`news-banner-${id}`, true);
  };

  useEffect(() => {
    // force re-render component, because of re-hydration issue (client rendered content differs from server rendered) (https://github.com/gatsbyjs/gatsby/issues/14601)
    const visible =
      isPlatformBrowser && !localStorageService.get<boolean>(`news-banner-${id}`) && todayMidnight <= expiryMidnight;
    setVisibility(visible);
    onVisibilityChange && onVisibilityChange(visible);
  }, []);

  return (
    <>
      {visible && (
        <div className={styles.newsBanner}>
          <div className={`centered ${styles.wrapper}`}>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
            <span onClick={dismiss} role="button" aria-label="Bezár">
              <Icon className={styles.icon} name="x" aria-hidden="true" />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsBanner;
